
		$cdnUrl: 'undefined';
		$fa-font-path: '/fonts/font-awesome';
	  
@import '../../../style/grids.scss';
@import '../../../style/font-family.scss';
@import '../../../style/globalColorVars.scss';

// item-meta is flexed in the grid.scss file.
.item-meta {
 position: relative;
 display: block;
 width: 100%;
 justify-content: space-between;

  a {
  color: $link-primary;
  font-size: 14px;

    &:hover {
     @include roboto-medium;

     > svg {
		color: $link-primary;
     }
    }

    svg {
   height: 14px;
   color: $link-primary;
  }
 }

 &.series {
  a {
   float: left;
  }
 }
}

.body-header{
 position: relative;
 display: flex;
 height: 28px;
 width: 100%;
 align-items: center;
 margin-bottom: 8px;


 .body-comp-logo {
  position: absolute;
  right: 0;
  margin-right: 0;
  width: 70px;
  height: 28px;
 }
}

.main-body {

 .item-title {
  margin-bottom: 15px;
 }
 .card-description {
  margin-bottom: 30px;
 }
}

.series-count {
 font-size: 14px;
}

.vertical-list {

  .search-summit-item {

  .item-title {
    margin-bottom: 0;
  }

  .item-meta {
    margin-top: 15px;
    justify-content: left;
  }
 }

 .search-summit-item {

  img {
    background-color: transparent;
  }
 }
}
