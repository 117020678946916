
		$cdnUrl: 'undefined';
		$fa-font-path: '/fonts/font-awesome';
	  
@use '../../../style/skeletonGrids.scss';
@use '../../../style/grids.scss';

.vertical-list{
  .main-body{
    width: 100%;
    .item-title,
    .item-meta{
      height: 20px;
      margin-top: 5px;
    }
    .card-description{
      height: 45px;
    }
  }
}
