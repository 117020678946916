
		$cdnUrl: 'undefined';
		$fa-font-path: '/fonts/font-awesome';
	  
@import '../../style/font-family.scss';

.no-results-section {
  @include roboto-light;

  .no-results-head {
    @include roboto-light;
    font-size: 22px;
    margin-bottom: 18px;

    .results-head {
      margin-left: 6px;
    }

    .search-string {
      @include roboto-medium;
    }
  }

  .no-results-sub-head {
    font-size: 16px;
  }

  ul{
    padding-left: 16px;

    li {
    list-style: circle;
    padding-bottom: 16px;
    list-style-type: disc;
    }
  }
}
